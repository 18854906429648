import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import scrollTo from '../scrollTo';

const Navigation = () => {
  const [isOpened, setIsOpened] = useState(false);

  const toggleDrawer = isOpen => event => {
    event.preventDefault();

    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsOpened(isOpen);
  };

  return (
    <div>
      <a
        href=''
        style={{
          position: 'fixed',
          top: '1em',
          right: '1em',
          padding: '0.5em',
          color: '#fff',
          backgroundColor: '#343a40',
          zIndex: 999
        }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </a>
      <div>
        <Drawer anchor='right' open={isOpened} onClose={toggleDrawer(false)}>
          <div
            role='presentation'
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <div>
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              {[
                { target: 'top', title: 'TOP' },
                {
                  target: 'about',
                  title: 'ABOUT'
                },
                {
                  target: 'services',
                  title: 'SERVICES'
                },
                {
                  target: 'shop',
                  title: 'SHOP'
                },
                {
                  target: 'pricing',
                  title: 'PRICING'
                },
                {
                  target: 'contact',
                  title: 'CONTACT'
                }
              ].map(({ target, title }) => (
                <ListItem
                  button
                  key={target}
                  onClick={() => {
                    scrollTo(target);
                  }}
                >
                  <ListItemText
                    primary={title}
                    sx={{
                      px: 5
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default Navigation;
