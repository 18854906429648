import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ServicesCoating from '../../images/services-coating.png';
import ServicesPolishing from '../../images/services-polishing.png';
import ServicesWashing from '../../images/services-washing.png';

const Services = () => (
  <Box
    id='services'
    component='section'
    sx={{
      backgroundColor: '#343a40',
      color: '#fff'
    }}
  >
    <Container>
      <Typography variant='subtitle1' color='primary' align='center'>
        SERVICES
      </Typography>
      <Typography
        variant='h2'
        align='center'
        sx={{
          mb: 5
        }}
      >
        サービス
      </Typography>
      <Grid container justifyContent='center' spacing={3}>
        {[
          {
            image: ServicesCoating,
            name: 'カーコーティング',
            description:
              'ボディにコーティングすることで塗装の状態を美しく保護することができます。'
          },
          {
            image: ServicesPolishing,
            name: 'カーポリッシング',
            description:
              'ボディを研磨することで新車時に近い輝きを取り戻すことができます。'
          },
          {
            image: ServicesWashing,
            name: '手洗い洗車',
            description: '純水を使用し、丁寧に手洗い洗車をさせていただきます。'
          }
        ].map(({ image, name, description }, i) => {
          return (
            <Grid item md={4} key={i}>
              <img
                src={image}
                alt={name}
                style={{
                  display: 'block',
                  margin: '0 auto',
                  width: '200px',
                  height: '200px'
                }}
              />
              <Typography
                variant='h3'
                align='center'
                sx={{
                  margin: '0.5em 0'
                }}
              >
                {name}
              </Typography>
              <Typography>{description}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  </Box>
);

export default Services;
