import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const mailAddress = 'autodetailinggarage.refine@gmail.com';

const Contact = () => (
  <Box
    id='contact'
    component='section'
    sx={{
      backgroundColor: '#fff'
    }}
  >
    <Container
      sx={{
        textAlign: 'center'
      }}
    >
      <Typography variant='subtitle1' color='primary'>
        CONTACT
      </Typography>
      <Typography
        variant='h2'
        sx={{
          mb: 5
        }}
      >
        お問い合わせ
      </Typography>
      <Link
        href={`mailto:${mailAddress}`}
        variant='body2'
        color='#333'
        sx={{
          my: 2
        }}
      >
        <EmailOutlinedIcon />
        {mailAddress}
      </Link>
    </Container>
  </Box>
);

export default Contact;
