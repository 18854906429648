import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import scrollTo from '../../scrollTo';
import LogoImage from '../../images/logo.png';

const Header = () => {
  const [height, setHeight] = useState(0);

  const resizeHeight = () => {
    setHeight(window.document.documentElement.clientHeight);
  };

  useEffect(() => {
    // @NOTE CSSの100vhではSafariのアドレスバーを含めて計算してしまうためJS制御で縦幅100%にする。
    resizeHeight();
    window.addEventListener('resize', resizeHeight);

    return () => {
      window.removeEventListener('resize', resizeHeight);
    };
  }, []);

  return (
    <Box
      id='top'
      component='header'
      sx={{
        position: 'relative',
        width: '100%',
        height: `${height}px`
      }}
    >
      <Link
        href='#'
        onClick={e => {
          e.preventDefault();
          scrollTo('about');
        }}
        sx={{
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `url(${LogoImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          backgroundSize: '35%'
        }}
      />
    </Box>
  );
};

export default Header;
