import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => (
  <Box
    id='footer'
    component='footer'
    sx={{
      backgroundColor: '#343a40',
      color: '#fff'
    }}
  >
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 10
        }}
      >
        {[
          {
            href: 'https://www.instagram.com/refine392/',
            Icon: InstagramIcon
          },
          {
            href: 'https://twitter.com/refine_coating',
            Icon: TwitterIcon
          }
        ].map(({ href, Icon }) => (
          <Link
            key={href}
            href={href}
            color='#fff'
            target='_blank'
            rel='noopener noreferrer'
            sx={{
              mx: 5,
              '&:hover': {
                color: '#aaa'
              }
            }}
          >
            <Icon fontSize='large' />
          </Link>
        ))}
      </Box>
      <Typography variant='body2' color='#6c757d' align='center'>
        &copy; 2022 Refine
      </Typography>
    </Container>
  </Box>
);

export default Footer;
