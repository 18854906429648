import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const About = () => (
  <Box
    id='about'
    component='section'
    sx={{
      backgroundColor: '#fff'
    }}
  >
    <Container
      sx={{
        textAlign: 'center'
      }}
    >
      <Typography variant='subtitle1' color='primary'>
        ABOUT
      </Typography>
      <Typography
        variant='h2'
        sx={{
          mb: 5
        }}
      >
        当店について
      </Typography>
      <Typography
        sx={{
          my: 2,
          fontSize: 20
        }}
      >
        車体を研ぎ澄まし守る。
      </Typography>
      <Typography
        sx={{
          my: 2,
          fontSize: 20
        }}
      >
        厳選されたコーティング剤と匠の技術で、最高の仕上がりをご提供いたします。
      </Typography>
      <Typography variant='body2'>
        当店では、東京モーターショーや東京オートサロン会場でも用いられている
        <br />
        厳選された <i>"Made in Japan"</i>{' '}
        の研磨資材やコーティング剤を使用しています。
      </Typography>
    </Container>
  </Box>
);

export default About;
