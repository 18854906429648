import { scroller } from 'react-scroll';

const scrollTo = target => {
  scroller.scrollTo(target, {
    duration: 500,
    smooth: true
  });
};

export default scrollTo;
