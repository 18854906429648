import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const Pricing = () => {
  const CustomTable = ({ title, data }) => (
    <Box mt={8}>
      <Typography
        variant='h5'
        color='primary'
        dangerouslySetInnerHTML={{
          __html: title
        }}
      />
      <TableContainer
        sx={{
          backgroundColor: '#fff',
          color: '#333'
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {data[0].map((head, i) => (
                <TableCell
                  key={i}
                  align='center'
                  sx={{
                    fontWeight: 'bold'
                  }}
                >
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(1).map((rows, rowsIndex) => (
              <TableRow key={rowsIndex}>
                {rows.map((col, colIndex) => (
                  <TableCell
                    key={colIndex}
                    align={colIndex === 0 ? 'center' : 'right'}
                    dangerouslySetInnerHTML={{
                      __html: colIndex === 0 ? col : col.toLocaleString() + '円'
                    }}
                    sx={{
                      fontWeight: colIndex === 0 ? 'bold' : 'normal'
                    }}
                  />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Box
      id='pricing'
      component='section'
      sx={{
        backgroundColor: '#343a40',
        color: '#fff'
      }}
    >
      <Container>
        <Typography variant='subtitle1' color='primary' align='center'>
          PRICING
        </Typography>
        <Typography
          variant='h2'
          align='center'
          sx={{
            mb: 5
          }}
        >
          料金表
        </Typography>

        <Typography
          variant='h4'
          align='center'
          sx={{
            marginTop: 10
          }}
        >
          コーティングメニュー
        </Typography>

        <CustomTable
          title='3D HYBRID COAT'
          data={[
            ['', 'SS', 'S', 'M', 'L', 'LL', 'XL', '3L'],
            ['3層', 165000, 176000, 187000, 209000, 220000, 231000, 253000],
            ['2層', 132000, 143000, 154000, 176000, 187000, 198000, 220000],
            ['1層', 110000, 121000, 132000, 154000, 165000, 176000, 198000],
            [
              'オプション<br /><small>（窓ガラス、ホイールセット）</small>',
              33000,
              33000,
              36300,
              36300,
              39600,
              39600,
              42900
            ],
            [
              'コーティング1層追加',
              19800,
              19800,
              22000,
              22000,
              25000,
              25000,
              29700
            ]
          ]}
        />
        <ul
          style={{
            marginTop: 10
          }}
        >
          <li>研磨は鏡面研磨 お預かり期間 7〜10日</li>
        </ul>

        <CustomTable
          title='BLACK JACK'
          data={[
            ['', 'SS', 'S', 'M', 'L', 'LL', 'XL', '3L'],
            ['BLACK JACK', 77000, 82500, 99000, 110000, 132000, 143000, 154000],
            [
              'オプション<br /><small>（窓ガラス、ホイールセット）</small>',
              33000,
              33000,
              36300,
              36300,
              39600,
              39600,
              42900
            ],
            [
              'コーティング1層追加',
              9900,
              9900,
              13200,
              13200,
              16500,
              16500,
              16500
            ]
          ]}
        />
        <ul
          style={{
            marginTop: 10
          }}
        >
          <li>研磨は中間研磨 お預かり期間 3〜5日</li>
        </ul>

        <CustomTable
          title='IGUAZ g'
          data={[
            ['', 'SS', 'S', 'M', 'L', 'LL', 'XL', '3L'],
            ['IGUAZ g', 55000, 60500, 64900, 69300, 78650, 90750, 99900],
            [
              'オプション<br /><small>（窓ガラス、ホイールセット）</small>',
              33000,
              33000,
              36300,
              36300,
              39600,
              39600,
              42900
            ],
            ['磨きなし', 25000, 27500, 33000, 36300, 39600, 45000, 49900]
          ]}
        />
        <ul
          style={{
            marginTop: 10
          }}
        >
          <li>研磨は軽研磨 お預かり期間 3〜5日</li>
        </ul>

        <CustomTable
          title='ホイールコーティング'
          data={[
            [
              '',
              '14〜15インチ',
              '16〜17インチ',
              '18〜19インチ',
              '20〜21インチ',
              '22〜24インチ',
              '25インチ〜'
            ],
            ['ホイール4本', 12900, 15000, 17900, 19900, 22500, 25000]
          ]}
        />

        <CustomTable
          title='窓ガラスコーティング'
          data={[
            ['', 'SS, S, M, L, LL, XL, 3L サイズ'],
            ['フロント', 8800],
            ['フロント+サイド2面', 14300],
            ['全面', 19900]
          ]}
        />

        <CustomTable
          title='ポリッシュ<small>（磨き）</small>'
          data={[
            ['', 'SS', 'S', 'M', 'L', 'LL', 'XL', '3L'],
            ['軽研磨', 15950, 16500, 22000, 27500, 33000, 39600, 49500],
            ['中間研磨', 32450, 33000, 38500, 44000, 55000, 66000, 77000],
            ['鏡面研磨', 48950, 50050, 60500, 66000, 77000, 88000, 99000]
          ]}
        />

        <Typography
          variant='h4'
          align='center'
          sx={{
            marginTop: 10
          }}
        >
          洗車メニュー
        </Typography>

        <CustomTable
          title='純水洗車 <small>※当店コーティング施工車様 限定価格</small>'
          data={[
            ['', 'SS', 'S', 'M', 'L', 'LL', 'XL', '3L'],
            [
              'プレミアム洗車 <small>※1</small>',
              6000,
              6500,
              7000,
              8000,
              8500,
              9000,
              10000
            ],
            [
              '月定額洗車 <small>※2</small>',
              6000,
              6500,
              7000,
              8000,
              8500,
              9000,
              10000
            ],
            ['撥水洗車', 2700, 3000, 3200, 3500, 3800, 4100, 4500],
            ['洗車', 2200, 2500, 2700, 3000, 3300, 3600, 4000],
            ['室内清掃', 1890, 1990, 2090, 2290, 2490, 2690, 2990]
          ]}
        />
        <ol
          style={{
            marginTop: 10
          }}
        >
          <li>
            プレミアム洗車 ...
            洗車をしつつ軽いコーティングメンテナンスを行います。
          </li>
          <li>
            月定額洗車 ... 定額制でいつでも洗車OK <small>※要予約</small>
          </li>
        </ol>

        <CustomTable
          title='純水洗車 <small>※一般車様 価格</small>'
          data={[
            ['', 'SS', 'S', 'M', 'L', 'LL', 'XL', '3L'],
            ['プレミアム洗車', 6900, 7500, 7900, 8500, 8990, 9900, 11000],
            ['撥水洗車', 3700, 3750, 4300, 4600, 4900, 5200, 5600],
            ['洗車', 2700, 2750, 3300, 3600, 3900, 4200, 4600],
            ['室内清掃', 2250, 2500, 2600, 2990, 3000, 3150, 3300],
            ['簡易コーティング', 8800, 9900, 11000, 13200, 16500, 19800, 22000]
          ]}
        />
        <ul
          style={{
            marginTop: 10
          }}
        >
          <li>
            お客様自身での施工ができるよう、材料の販売（コーティング剤、クリーナー、マイクロファイバータオルなど）も実施しております。
          </li>
        </ul>
      </Container>
    </Box>
  );
};

export default Pricing;
