import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import StoreImage from '../../images/shop-store.jpg';
import WaitingImage from '../../images/shop-waiting.jpg';
import WashingImage from '../../images/shop-washing.jpg';
import PolishingImage from '../../images/shop-polishing.jpg';

const Shop = () => (
  <Box id='shop' component='section' sx={{ backgroundColor: '#fff' }}>
    <Container>
      <Typography variant='subtitle1' align='center' color='primary'>
        SHOP
      </Typography>
      <Typography
        variant='h2'
        align='center'
        sx={{
          mb: 5
        }}
      >
        店内の様子
      </Typography>
      <Box
        sx={{
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <Carousel>
          {[
            {
              caption: '店内',
              image: StoreImage
            },
            {
              caption: '待合室',
              image: WaitingImage
            },
            {
              caption: '洗車',
              image: WashingImage
            },
            {
              caption: 'ポリッシュ',
              image: PolishingImage
            }
          ].map(({ caption, image }, i) => (
            <Box key={i}>
              <img alt={caption} src={image} />
              <Typography className='legend'>{caption}</Typography>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Container>
  </Box>
);

export default Shop;
