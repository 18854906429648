import React from 'react';

const Map = () => (
  <iframe
    title='MAP'
    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3286.2274229265963!2d135.7049905152221!3d34.54779678047398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60012ef3205d7dc1%3A0x9a6ef13e3c73ca8!2zUmVmaW5lIOOAkOOCq-ODvOOCs-ODvOODhuOCo-ODs-OCsCDlpYjoia_jgJHjgJDjgrPjg7zjg4bjgqPjg7PjgrDlsILploDlupfjgJE!5e0!3m2!1sja!2sjp!4v1573209414197!5m2!1sja!2sjp'
    width={800}
    height={450}
    frameBorder={0}
    style={{
      display: 'block',
      width: '100vw',
      height: '50vh',
      border: 0
    }}
    allowFullScreen={false}
  ></iframe>
);

export default Map;
