import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Navigation from '../components/navigation';
import Header from '../components/sections/header';
import SectionAbout from '../components/sections/about';
import SectionServices from '../components/sections/services';
import SectionShop from '../components/sections/shop';
import SectionPricing from '../components/sections/pricing';
import SectionContact from '../components/sections/contact';
import SectionMap from '../components/sections/map';
import SectionFooter from '../components/sections/footer';
import HeaderImage from '../images/header.jpg';
import '../layout.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ecb807'
    }
  },
  typography: {
    h2: {
      fontSize: 32,
      fontWeight: 'bold'
    },
    h3: {
      fontSize: 24,
      fontWeight: 'bold'
    },
    h4: {
      fontSize: 24
    },
    h5: {
      fontSize: 20
    }
  }
});

const Index = props => {
  const { siteMetadata } = props.data.site;

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name='description' content={siteMetadata.description} />
        <title>{siteMetadata.title}</title>
        <link rel='canonical' href={siteMetadata.siteUrl} />
        <meta name='author' content='Refine' />
        <meta name='theme-color' content='#000000' />
        <meta name='msapplication-TileColor' content='#000000' />
        <meta property='og:url' content={siteMetadata.siteUrl} />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={siteMetadata.title} />
        <meta property='og:description' content={siteMetadata.description} />
        <meta property='og:site_name' content={siteMetadata.siteName} />
        <meta property='og:image' content={siteMetadata.ogpUrl} />
        <meta property='og:image:secure_url' content={siteMetadata.ogpUrl} />
        <meta property='og:image:type' content='image/png' />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property='og:locale' content={siteMetadata.locale} />
        <meta property='og:locale:alternate' content={siteMetadata.locale} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@auto_refine' />
        <meta name='twitter:creator' content='@auto_refine' />
        <meta name='twitter:title' content={siteMetadata.title} />
        <meta name='twitter:description' content={siteMetadata.description} />
        <meta name='twitter:image' content={siteMetadata.ogpUrl} />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='https://carcoatingrefine.com/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='https://carcoatingrefine.com/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='https://carcoatingrefine.com/favicon-16x16.png'
        />
        <link
          rel='mask-icon'
          href='https://carcoatingrefine.com/safari-pinned-tab.svg'
          color='#000000'
        />
        <link
          rel='shortcut icon'
          href='https://carcoatingrefine.com/favicon.ico'
        />
        <style>{`
        body:before {
          background-image: url(${HeaderImage});
        }
        `}</style>
      </Helmet>
      <Navigation />
      <Header />
      <SectionAbout />
      <SectionServices />
      <SectionShop />
      <SectionPricing />
      <SectionContact />
      <SectionMap />
      <SectionFooter />
    </ThemeProvider>
  );
};

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        lang
        locale
        title
        siteName
        description
        siteUrl
        ogpUrl
      }
    }
  }
`;

export default Index;
